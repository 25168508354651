import axios, { AxiosError, AxiosResponse } from 'axios'
import jwt_decode from 'jwt-decode'
import { DateTime } from 'luxon'
import { checkCommitHashFromBaseTag } from 'parkdepot-shared/utils/versionFetcher'
import { VERSION_FETCH_TIMESTAMP_MILLISECONDS } from 'parkdepot-shared/utils/versionFetcher/constants'
import { LocalStorageKey } from 'parkdepot-shared/utils/versionFetcher/types'
import { TokenContext } from 'parkdepot-shared/views/token'
import { MixpanelContext } from 'parkdepot-shared/views/tracking'
import React, { Suspense } from 'react'
import { useHistory } from 'react-router-dom'
import i18n, { LANGUAGE_FALLBACKS } from '../../i18n'
import { configAxios } from '../../utils/axiosConfig'
import AppLoading from '../AppLoading'
import PreflightProvider, { IAuthData, IPreflightData } from './PreflightContext'

const PreflightContainer: React.FunctionComponent = ({ children }) => {
    const { token } = React.useContext(TokenContext)

    const history = useHistory()
    const [loading, setLoading] = React.useState(true)
    const [preflightStatusCode, setPreflightStatusCode] = React.useState<number | undefined>(undefined)
    const [preflightData, setPreflightData] = React.useState<IPreflightData>({} as IPreflightData)
    const { track } = React.useContext(MixpanelContext)

    const versionInterval = React.useRef<NodeJS.Timer>()

    React.useEffect(() => {
        const versionGetter = async () => {
            if (process.env.NODE_ENV === 'development') {
                return
            }

            // in case if some of the version check data is corrupted
            localStorage.removeItem(LocalStorageKey.VERSION_COMMIT_HASH)

            versionInterval.current = setInterval(async () => {
                checkCommitHashFromBaseTag()
            }, VERSION_FETCH_TIMESTAMP_MILLISECONDS)
        }

        versionGetter()
        return () => {
            clearInterval(versionInterval.current as NodeJS.Timeout)
        }
        // eslint-disable-next-line
    }, [])

    const triggerPreflightRequest = async () => {
        if (!token) {
            setLoading(false)
            return
        }

        // set Authorization header for all axios requests
        axios.defaults.headers.common.Authorization = token

        //eslint-disable-next-line promise/catch-or-return
        axios
            .post(`${process.env.REACT_APP_API_URL}customer/guest_preflight_request`)
            .then((response: AxiosResponse) => {
                setPreflightStatusCode(response.status)

                if (response.status !== 200) {
                    return
                }

                const preflightResponse = JSON.parse(response.data.body)
                const language = preflightResponse.language.split('_')[0]
                const countryCode = Object.entries(LANGUAGE_FALLBACKS).reduce((acc, [key, value]) => {
                    if (language && value.includes(language)) {
                        acc = key
                    }
                    return acc
                }, 'de')

                if (countryCode && !['de', 'at', 'ch'].includes(countryCode)) {
                    i18n.changeLanguage(`${countryCode}-${language}`)
                }

                setPreflightData({ ...JSON.parse(response.data.body), accessToken: token })
            })
            .catch((error: AxiosError) => {
                console.error(error)
                setPreflightStatusCode(error.response?.status || 404)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    React.useEffect(() => {
        if (loading || !preflightStatusCode) return

        switch (preflightStatusCode) {
            case 200:
                track('correct_token')
                break
            case 400:
                track('expired_token')
                history.push('/expired-token')
                break
            case 403:
                track('invalid_timerange')
                history.push('/outside-opening-hours')
                break
            case 404:
            default:
                track('error_validation_token')
                history.push('/insufficient-permissions')
                break
        }
        // eslint-disable-next-line
    }, [history, loading, preflightStatusCode, track])

    // populate preflight response on first render only
    React.useEffect(() => {
        if (!token) {
            setLoading(false)
            return
        }

        configAxios(token)

        // decode the JWT token
        const { id: jwt_id, exp: expires_at }: IAuthData = jwt_decode(token)

        // otherwise the preflight data is prepolutated
        // with the decoded information from the JWT
        setPreflightData({
            ...preflightData,
            jwt_id,
            expires_at
        })

        if (jwt_id) {
            // only trigger preflight request if a jwt id is found
            triggerPreflightRequest()
        } else {
            // if there is no ID within the JWT, the guest access validation is through the expiration date property
            const statusCode = isNaN(expires_at) || DateTime.local() > DateTime.fromSeconds(expires_at) ? 400 : 200

            setPreflightStatusCode(statusCode)
            setLoading(false)
            return
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token])

    return preflightData ? (
        <Suspense fallback={null}>
            <PreflightProvider
                context={{
                    preflightData,
                    preflightStatusCode,
                    preflightLoading: loading
                }}
            >
                {children}
            </PreflightProvider>
        </Suspense>
    ) : (
        <AppLoading />
    )
}

export default PreflightContainer

import { useStyletron } from 'baseui'
import Button from 'parkdepot-shared/components/Buttons/Button'
import LPInput from 'parkdepot-shared/components/LPInput'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { CUSTOM_MEDIA_QUERIES } from '../AppFrame/theme'
import { PreflightContext } from '../PreFlight/PreflightContext'
import { MemberEntryContext } from './MemberEntryContext'
import { GUEST_UI_STYLES, TABLET_TYPOGRAHPY_OVERRIDE } from './constants'

type ExceedDailyLimitScreenProps = {
    hideModal: () => void
}

const ExceedDailyLimitScreen = ({ hideModal }: ExceedDailyLimitScreenProps) => {
    const { t } = useTranslation()
    const [css, theme] = useStyletron()
    const { data } = React.useContext(MemberEntryContext)
    const { preflightData } = React.useContext(PreflightContext)

    return (
        <div
            id="error-modal-container"
            className={css({
                left: 0,
                bottom: 0,
                top: 0,
                right: 0,
                position: 'fixed',
                overflow: 'auto',
                zIndex: 2
            })}
        >
            <div
                className={css({
                    display: 'flex',
                    width: '100%',
                    minHeight: '100vh',
                    background: '#FFFFFF',
                    justifyContent: 'center',
                    alignItems: 'center'
                })}
            >
                <div
                    className={css({
                        backgroundColor: theme.colors.negative,
                        borderRadius: '25px',
                        paddingLeft: theme.sizing.scale600,
                        paddingRight: theme.sizing.scale600,
                        transition: 'margin 200ms',
                        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
                            paddingLeft: theme.sizing.scale1200,
                            paddingRight: theme.sizing.scale1200
                        },
                        width: '90%',
                        zIndex: 1
                    })}
                >
                    <div
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            paddingTop: theme.sizing.scale1400,
                            color: '#fff',
                            marginTop: '0px',
                            marginBottom: theme.sizing.scale600,
                            ...TABLET_TYPOGRAHPY_OVERRIDE.large
                        })}
                    >
                        <i className={`${css({ fill: '#fff', fontSize: '40px' })} wemolo-warning-circle`} />
                        <div
                            className={css({
                                ...GUEST_UI_STYLES.SUCCESS_HEADER,
                                textAlign: 'center'
                            })}
                        >
                            {t('member.memberEntry.error.dailylimit.title')}
                        </div>
                    </div>
                    <div
                        className={css({
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            [theme.mediaQuery.medium]: {
                                paddingLeft: '10px',
                                paddingRight: '10px'
                            }
                        })}
                    >
                        <div>
                            <div
                                className={css({
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    marginBottom: theme.sizing.scale600
                                })}
                            >
                                <div
                                    className={css({
                                        width: '100%',
                                        marginBottom: theme.sizing.scale500,
                                        [CUSTOM_MEDIA_QUERIES.largerThanPhone]: { width: '460px' }
                                    })}
                                >
                                    <LPInput
                                        disabled
                                        value={data.plate}
                                        country={data.country}
                                        width="100%"
                                        overrides={{
                                            CircularBadge: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.BADGES.CIRCULAR
                                            },
                                            PlateBadge: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.BADGES.PLATE
                                            },
                                            LPContainer: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.HEIGHT
                                            },
                                            LPInnerContainer: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.HEIGHT
                                            },
                                            Input: {
                                                ...GUEST_UI_STYLES.SUCCESS_LP_INPUT
                                            },
                                            DropdownButton: {
                                                ...GUEST_UI_STYLES.LICENSE_PLATE.BUTTON
                                            }
                                        }}
                                    />
                                </div>
                                <div
                                    className={css({
                                        textAlign: 'center',
                                        fontSize: '24px',
                                        color: theme.colors.white,
                                        fontWeight: 400,
                                        marginBlock: theme.sizing.scale600
                                    })}
                                >
                                    {t('member.memberEntry.error.dailylimit.description', {
                                        dailyLimit:
                                            preflightData?.scans_per_day === 1
                                                ? `1 ${t('common.units.entry.one')}`
                                                : `${preflightData?.scans_per_day} ${t('common.units.entry.other')}`
                                    })}
                                </div>
                            </div>
                        </div>
                        <div
                            id="success_email_container"
                            className={css({
                                display: 'flex',
                                paddingBottom: theme.sizing.scale1400,
                                [CUSTOM_MEDIA_QUERIES.largerThanPhone]: {
                                    paddingBottom: theme.sizing.scale1600
                                }
                            })}
                        >
                            <Button
                                $dark
                                onClick={hideModal}
                                overrides={{
                                    BaseButton: {
                                        style: {
                                            width: '100%',
                                            ...GUEST_UI_STYLES.CONFIRM_SUCCESS_BUTTON,
                                            color: theme.colors.black
                                        }
                                    }
                                }}
                            >
                                {t('common.button.back')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExceedDailyLimitScreen

import { AxiosError } from 'axios'
import { DateTime, Duration } from 'luxon'
import countries from 'parkdepot-shared/assets/countries.json'
import { JWT_PURPOSE } from 'parkdepot-shared/types'
import useLicensePlateValidation from 'parkdepot-shared/utils/hooks/useLicensePlateValidation'
import { MixpanelContext } from 'parkdepot-shared/views/tracking'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useImmer } from 'use-immer'
import useSendEmail from '../../hooks/useSendEmail'
import insertMembershipEntry from '../../module/insertMembershipEntry'
import { getCountry, getLanguage } from '../../utils/helper'
import useErrorMessageHandler from '../../utils/hooks/useErrorMessageHandler'
import generateValidator from '../../utils/validateForms'
import { PreflightContext } from '../PreFlight/PreflightContext'
import MemberEntryProvider, { IMemberEntryContext, IMemberEntryIntermediate } from './MemberEntryContext'
import {
    emailValidation as emailSchema,
    whitelistValidation as whitelistSchema
} from './validation/whitelistValidation'

const INITIAL_MEMBER_ENTRY = {
    plate: '',
    name: '',
    created_at: DateTime.utc().toISO() as string,
    end_date: DateTime.local().toISO() as string,
    language: countries.germany,
    country: countries.germany,
    email: undefined,
    readPolicy: false,
    anonymous: true,
    whitelist_duration: '',
    purpose: ''
}

const MemberEntryContainer: React.FunctionComponent = ({ children }) => {
    const { t } = useTranslation()
    const { sendEmail } = useSendEmail()
    const { handleAPIError, hideErrorSnackbar } = useErrorMessageHandler()

    const { track } = React.useContext(MixpanelContext)
    const { preflightData, preflightLoading } = React.useContext(PreflightContext)

    const [errors, setErrors] = React.useState({})
    const [entryCreated, setEntryCreated] = React.useState(false)
    const [isDailyLimitExceeded, setIsDailyLimitExceeded] = React.useState(false)
    const [createLoading, setCreateLoading] = React.useState(false)

    const [intermediateData, edit] = useImmer<IMemberEntryIntermediate>(INITIAL_MEMBER_ENTRY)

    const data = {
        ...intermediateData,
        start_date: DateTime.local().toISO() || undefined
    }

    const validateLicensePlate = useLicensePlateValidation()

    const reset = React.useCallback(() => {
        edit((draft) => {
            const duration = preflightData.whitelist_duration || draft.whitelist_duration
            const purpose = preflightData.purpose || draft.purpose
            const language = preflightData.language || draft.language
            const country = preflightData.country || draft.country
            const anonymous = preflightData.anonymous

            return {
                plate: '',
                name: '',
                created_at: DateTime.utc().toISO() || '',
                end_date: duration ? DateTime.local().plus(Duration.fromISO(duration)).toISO() || '' : undefined,
                language: getLanguage(language, country),
                country: getCountry(language, country),
                email: '',
                readPolicy: false,
                anonymous: anonymous,
                whitelist_duration: duration,
                purpose: purpose ? purpose : JWT_PURPOSE.CUSTOMER_WHITELIST
            }
        })
        setErrors({})
        setEntryCreated(false)
    }, [edit, preflightData])

    React.useEffect(reset, [])

    const context: IMemberEntryContext = {
        data,
        edit,
        loading: createLoading || preflightLoading,
        validationErrors: errors,
        jwt_id: preflightData.jwt_id,
        resetErrors: () => {
            setErrors({})
        },
        create: async () => {
            const whitelistValidation = generateValidator(whitelistSchema, {
                'date.min': () => t('modals.createWhitelistEntry.errors.date.min'),
                'date.max': () => t('modals.createWhitelistEntry.errors.date.max'),
                'any.required': () => t('modals.createWhitelistEntry.errors.date.base'),
                'any.ref': () => t('modals.createWhitelistEntry.errors.date.base'),
                'any.only': () => t('errors.string.empty'),
                'string.pattern.base': () => t('modals.createWhitelistEntry.errors.plateNoWhitespace'),
                'string.empty': () => t('errors.string.empty')
            })

            const result = whitelistValidation(data)
            const plateResult = validateLicensePlate(data.plate, data.country)

            if (result.valid && plateResult.valid) {
                setCreateLoading(true)

                insertMembershipEntry({
                    email: data.email,
                    plate: data.plate,
                    country: data.country.toUpperCase(),
                    name: data.name,
                    handleAPIError: () => {}
                })
                    .then(() => {
                        track('whitelisted', {
                            lp_type: data.country
                        })
                        setCreateLoading(false)
                        setEntryCreated(true)
                        hideErrorSnackbar()
                        setErrors({})
                    })
                    .catch((err: AxiosError) => {
                        if (err.response?.status === 403) {
                            setIsDailyLimitExceeded(true)
                        } else {
                            handleAPIError(err)
                        }
                        setCreateLoading(false)
                    })
            } else {
                setErrors({ ...result.errors, ...plateResult.errors })
            }
        },
        cancel: () => {
            hideErrorSnackbar()
            reset()
        },
        sendEmail: async () => {
            const emailValidator = generateValidator(emailSchema, {
                'string.invalid': () => t('errors.string.email')
            })

            const emailValidation = emailValidator(data.email)

            if (!data.email) return Promise.resolve({})
            if (!emailValidation.valid) {
                setErrors({ email: emailValidation.errors[''] })
                return Promise.resolve({ error: true })
            }

            return sendEmail({
                email: data.email!,
                plate: data.plate
            })
        },
        entryCreated,
        isDailyLimitExceeded,
        hideDailyLimitExceededError: () => setIsDailyLimitExceeded(false)
    }

    return <MemberEntryProvider context={context}>{children}</MemberEntryProvider>
}

export default MemberEntryContainer

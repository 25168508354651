import axios from 'axios'

export function configAxios(token: string) {
    axios.defaults.headers.common = {
        'Content-Type': 'application/json'
    }
    axios.defaults.headers.common.Authorization = token

    axios.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            return Promise.reject(error)
        }
    )
}

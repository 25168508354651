import { useStyletron } from 'baseui'
import { FileUploader, FileUploaderProps, StyledFileDragAndDrop } from 'baseui/file-uploader'
import { DURATION, useSnackbar } from 'baseui/snackbar'
import { LabelSmall, LabelXSmall } from 'baseui/typography'
import imageCompression from 'browser-image-compression'
import Button from 'parkdepot-shared/components/Buttons/Button'
import { BUTTON_KIND } from 'parkdepot-shared/components/Buttons/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { v1 as uuidv1 } from 'uuid'
import { EvEntryContext } from '../../EvEntryContext'
import UploadErrorNotification from '../UploadErrorNotification'
import config from './config'

const FileUploaderComponent = () => {
    const { t } = useTranslation()
    const [css, theme] = useStyletron()

    const { data, validationErrors, edit, validateEntry } = React.useContext(EvEntryContext)

    const [loading, setLoading] = React.useState(false)
    const [errors, setErrors] = React.useState<Array<'tooLarge' | 'wrongFormat'>>([])

    const { enqueue, dequeue } = useSnackbar()

    const enqueUploadErrorNotification = (files: File[], translationKey: string) => {
        enqueue(
            {
                message: t(translationKey),
                overrides: {
                    Root: {
                        style: {
                            backgroundColor: '#fff !important',
                            background: '#fff !important'
                        }
                    },
                    Message: {
                        style: {
                            backgroundColor: '#fff !important',
                            background: '#fff !important'
                        }
                    },
                    Content: {
                        style: {
                            backgroundColor: '#fff !important',
                            background: '#fff !important'
                        },
                        component: () => {
                            return (
                                <UploadErrorNotification
                                    files={files}
                                    translationKey={translationKey}
                                    dequeue={dequeue}
                                />
                            )
                        }
                    }
                }
            },
            DURATION.long * 2
        )
    }

    //Generation of submitted attachments into base-64 files
    const generateAttachments = async (acceptedFiles: File[]) => {
        const promises = acceptedFiles.map(async (file) => {
            return { name: file.name, body: file, type: file.type, size: file.size, id: uuidv1() }
        })
        const result = await Promise.all(promises)
        const attachmentsReady = result.filter((element) => !!element)
        edit((draft) => {
            draft.attachments = [...draft.attachments, ...attachmentsReady]
        })
    }

    const isImage = (type: string) => type.split('/')?.[0] === 'image'

    const renderErrorFromUpload = () => {
        if (errors.length > 1) {
            const errorsTranslationsStrings = [
                t('ev.evEntry.attachments.errors.tooLarge'),
                t('ev.evEntry.attachments.errors.wrongFormat')
            ]
            return (
                <div>
                    {errorsTranslationsStrings.map((errorTranslationString) => (
                        <div key={errorTranslationString}>{errorTranslationString}</div>
                    ))}
                </div>
            )
        }

        return t(`ev.evEntry.attachments.errors.${errors[0]}`)
    }

    return (
        <>
            <LabelSmall
                className={css({
                    marginTop: theme.sizing.scale1000,
                    marginBottom: theme.sizing.scale300,
                    lineHeight: `${theme.sizing.scale800} !important`,
                    color: `${theme.colors.contentAccent} !important`,
                    [theme.mediaQuery.medium]: {
                        marginBottom: theme.sizing.scale600,
                        marginTop: '52px'
                    }
                })}
            >
                {t('ev.evEntry.uploadReceipt')}
            </LabelSmall>
            <FileUploader
                accept={Object.values(config.acceptedMimeTypes)}
                maxSize={config.maxUploadSize}
                errorMessage={
                    errors.length ? errors.map((error) => t(`ev.evEntry.attachments.errors.${error}`)).join(' ') : ''
                }
                progressMessage={loading ? t('ev.evEntry.uploading') : ''}
                onDropAccepted={async (acceptedFiles) => {
                    setLoading(true)

                    const attachmentSize = [...data.attachments, ...acceptedFiles].reduce((acc, attachment) => {
                        acc += attachment.size
                        return acc
                    }, 0)

                    if (attachmentSize > config.overallMaxSize) {
                        enqueUploadErrorNotification(acceptedFiles, 'ev.evEntry.attachments.errors.tooLarge')
                        errors.push('tooLarge')
                        setLoading(false)
                        return
                    }
                    await generateAttachments([...acceptedFiles.filter((file) => file)])
                    validateEntry()
                    setLoading(false)
                }}
                onDropRejected={async (rejectedFiles) => {
                    const acceptedFiles: File[] = []
                    const tooBigFiles: File[] = []

                    const parsedFiles = await Promise.all(
                        rejectedFiles.map((rejectedFile) => {
                            if (
                                !isImage(rejectedFile.type) &&
                                Object.values(config.acceptedMimeTypes).includes(rejectedFile.type) &&
                                rejectedFile.size > config.maxUploadSize
                            ) {
                                tooBigFiles.push(rejectedFile)
                                return Promise.resolve(undefined)
                            }

                            if (!isImage(rejectedFile.type)) {
                                return Promise.resolve(undefined)
                            }

                            return imageCompression(rejectedFile, {
                                maxSizeMB: config.maxImageCompressionSizeMb,
                                useWebWorker: false,
                                maxWidthOrHeight: config.maxImageWidthOrHeight
                            })
                        })
                    )

                    const filteredFiles = parsedFiles.filter((parsedFile) => parsedFile) as File[]

                    filteredFiles.forEach((parsedFile) => {
                        if (parsedFile.size > config.maxUploadSize) {
                            return
                        }

                        acceptedFiles.push(parsedFile)
                    })

                    const wrongFormatFiles = rejectedFiles.filter(
                        (rejectedFile) =>
                            !Object.values(config.acceptedMimeTypes).includes(rejectedFile.type) &&
                            !isImage(rejectedFile.type)
                    )

                    if (tooBigFiles.length && wrongFormatFiles.length && rejectedFiles.length !== 1) {
                        errors.push('tooLarge')
                        errors.push('wrongFormat')
                        return
                    }
                    if (tooBigFiles.length) {
                        enqueUploadErrorNotification(tooBigFiles, 'ev.evEntry.attachments.errors.tooLarge')
                        errors.push('tooLarge')
                        return
                    }
                    if (wrongFormatFiles.length) {
                        enqueUploadErrorNotification(wrongFormatFiles, 'ev.evEntry.attachments.errors.wrongFormat')
                        errors.push('wrongFormat')
                    }
                }}
                onRetry={() => {
                    setErrors([])
                }}
                overrides={{
                    Root: {
                        style: ({ $theme }) => ({
                            [$theme.mediaQuery.large]: {
                                width: '100%'
                            }
                        })
                    },
                    FileDragAndDrop: {
                        component: (props) => {
                            return <StyledFileDragAndDrop {...props} />
                        },
                        style: ({ $theme }) => ({
                            width: '100%',
                            borderRadius: '25px',
                            borderTopStyle: 'dashed',
                            borderBottomStyle: 'dashed',
                            borderLeftStyle: 'dashed',
                            borderRightStyle: 'dashed',
                            ...(errors.length
                                ? {
                                      borderTopColor: '#D92D20',
                                      borderBottomColor: '#D92D20',
                                      borderLeftColor: '#D92D20',
                                      borderRightColor: '#D92D20'
                                  }
                                : {
                                      borderTopColor: '#E5E5E5',
                                      borderBottomColor: '#E5E5E5',
                                      borderLeftColor: '#E5E5E5',
                                      borderRightColor: '#E5E5E5'
                                  }),
                            backgroundColor: '#FBFBFB',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: '80px',
                            paddingBottom: '80px',
                            paddingRight: $theme.sizing.scale850,
                            paddingLeft: $theme.sizing.scale850,
                            justifyContent: 'center',
                            alignItems: 'center'
                        })
                    },
                    ContentMessage: () => <></>,
                    ErrorMessage: {
                        // We use snackbars instead
                        component: () => {
                            return <></>
                        }
                    },
                    ButtonComponent: (props: FileUploaderProps) => {
                        return (
                            <>
                                <LabelSmall
                                    className={css({
                                        lineHeight: theme.sizing.scale700,
                                        color: '#ADADAD !important',
                                        textAlign: 'center',
                                        fontWeight: '400 !important',
                                        marginBottom: theme.sizing.scale600
                                    })}
                                >
                                    {t('ev.evEntry.uploadAttachments')}
                                </LabelSmall>
                                <Button
                                    size="compact"
                                    $kind={BUTTON_KIND.secondary}
                                    overrides={{
                                        Root: {
                                            style: {
                                                height: '40px',
                                                borderColor: theme.colors.black,
                                                borderTopWidth: theme.sizing.scale0,
                                                borderBottomWidth: theme.sizing.scale0,
                                                borderLeftWidth: theme.sizing.scale0,
                                                borderRightWidth: theme.sizing.scale0,
                                                borderRightStyle: 'solid',
                                                borderLeftStyle: 'solid',
                                                borderBottomStyle: 'solid',
                                                borderTopStyle: 'solid',
                                                color: theme.colors.contentAccent,
                                                borderTopLeftRadius: theme.sizing.scale300,
                                                borderTopRightRadius: theme.sizing.scale300,
                                                borderBottomLeftRadius: theme.sizing.scale300,
                                                borderBottomRightRadius: theme.sizing.scale300,
                                                marginTop: theme.sizing.scale600,
                                                fontSize: theme.sizing.scale550,
                                                lineHeight: theme.sizing.scale700,
                                                backgroundColor: theme.colors.white,
                                                ':hover': {
                                                    backgroundColor: theme.colors.white
                                                },
                                                ':focus': {
                                                    backgroundColor: theme.colors.white
                                                }
                                            }
                                        }
                                    }}
                                    onClick={(e) => {
                                        if (errors.length) {
                                            setErrors([])
                                            return
                                        }
                                        if (props.onClick)
                                            props.onClick(e as unknown as React.MouseEvent<HTMLElement, MouseEvent>)
                                    }}
                                >
                                    {t('ev.evEntry.button.search')}
                                </Button>
                            </>
                        )
                    }
                }}
            />
            {(validationErrors.attachments && !data.attachments.length) || errors.length ? (
                <LabelXSmall
                    className={css({
                        fontSize: theme.sizing.scale400,
                        lineHeight: theme.sizing.scale500,
                        fontWeight: '400 !important',
                        display: 'flex',
                        alignItems: 'center',
                        color: `#D92D20 !important`,
                        marginTop: theme.sizing.scale100,
                        [theme.mediaQuery.medium]: {
                            marginTop: theme.sizing.scale200
                        }
                    })}
                >
                    {errors.length ? renderErrorFromUpload() : t(validationErrors.attachments)}
                </LabelXSmall>
            ) : undefined}
            <LabelXSmall
                className={css({
                    fontSize: theme.sizing.scale500,
                    lineHeight: theme.sizing.scale650,
                    fontWeight: '400 !important',
                    display: 'flex',
                    alignItems: 'center',
                    color: `${theme.colors.contentAccent} !important`,
                    marginTop: theme.sizing.scale200,
                    [theme.mediaQuery.medium]: {
                        marginTop: theme.sizing.scale400
                    }
                })}
            >
                {t('ev.evEntry.uploadInstructions')}
            </LabelXSmall>
        </>
    )
}

export default FileUploaderComponent

import axios, { AxiosResponse } from 'axios'
import { getCommitFromBaseTag } from '../utils/getCommitFromBaseTag'

export const fetchVersionFromHTML = () => {
    return axios.get(window.location.origin).then((response: AxiosResponse<string>) => {
        const helperElement = document.createElement('div')

        helperElement.innerHTML = response.data

        return getCommitFromBaseTag(helperElement)
    })
}

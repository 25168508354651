import { fetchVersionFromHTML } from '../requests/fetchVersionFromHTML'
import { getCommitFromBaseTag } from './getCommitFromBaseTag'
import { LocalStorageKey } from '../types'

/**
 * Check if the commit hash has changed, if so, reload the page.
 * Enabled only in production (process.env.NODE_ENV !== 'development').
 * @returns void
 */
export const checkCommitHashFromBaseTag = async () => {
    if (process.env.NODE_ENV === 'development') {
        return
    }

    const commitHash = await getCommitFromBaseTag(document)

    if (!commitHash) {
        return
    }

    const nextCommitHash = await fetchVersionFromHTML()

    if (commitHash !== nextCommitHash) {
        localStorage.removeItem(LocalStorageKey.VERSION_CHECK_PENDING)
        window.location.reload()
    }
}

export default checkCommitHashFromBaseTag

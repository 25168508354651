/* eslint-disable */
import React from 'react'
import { Route, Switch } from 'react-router-dom'

import { JWT_PURPOSE } from 'parkdepot-shared/types'

// Route Views
import MemberEntry from './views/MemberEntry'
import EvEntry from './views/EvEntry'
import PageNotFound from './views/PageNotFound'
import GuestAccessError from './views/GuestAccessError'
import AppLoading from './views/AppLoading'
import { PreflightContext } from './views/PreFlight/PreflightContext'

const Routes = () => {
    const {
        preflightData: { purpose },
        preflightLoading,
        preflightStatusCode
    } = React.useContext(PreflightContext)

    return (
        <Switch>
            <Route path="/:errorType" component={GuestAccessError} />
            <Route path="/404" component={PageNotFound} />
            {preflightLoading ? (
                <AppLoading />
            ) : (
                preflightStatusCode === 200 && (
                    <Route path="/" component={purpose === JWT_PURPOSE.EV_CHARGING_PROOF ? EvEntry : MemberEntry} />
                )
            )}
            <Route component={PageNotFound} />
        </Switch>
    )
}

export default Routes
